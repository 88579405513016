import store from "../../store/index";
import axios from "axios";
import {
  mapState,
  mapActions,
  mapGetters
} from "vuex";
import {
  GetParam
} from "../../utils/getParams";
import {
  login,
  getmess,
  bindsubmit,
  searchDoc
} from "../../api/index";
import {
  api,
  config
} from "../../utils/urllist";
import preventBack from "vue-prevent-browser-back";
export default {
  data() {
    var checkName = (rule, value, callback) => {
      let phtest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
      if (!value) {
        return callback(new Error("请填入手机号码"));
      } else if (!phtest.test(value)) {
        return callback(new Error("请填入正确的手机号码"));
      } else if (value.toString().length !== 11) {
        return callback(new Error("电话号码必须是11位数字"));
      } else {
        callback();
      }
    };
    var checkHosp = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入医院名称"));
      } else {
        callback();
      }
    };
    var checkServeId = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入科室"));
      } else {
        callback();
      }
    };
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var checkYzm = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    var checkT = (rule, value, callback) => {
      if (value === false) {
        return callback(new Error("请勾选实名注册"));
      } else {
        callback();
      }
    };
    return {
      openId: "",
      headImgUrl: "",
      mixins: [preventBack], //注入
      nickName: "",
      pageshow: false,
      ruleForm: {
        name: "",
        verification: "", //绑定输入验证码框框
        registerHospitalName: "", //医院名称
        organizationName: "", //科室
        checktrue: false,
        username: "",
      },
      ruleFormbyPh: {
        name: "",
        verification: "", //绑定输入验证码框框
        registerHospitalName: "", //医院名称
        organizationName: "", //科室
        checktrue: false,
        username: "",
      },
      // logining: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      rules: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
        registerHospitalName: [{
          validator: checkHosp,
          trigger: "blur"
        }],
        organizationName: [{
          validator: checkServeId,
          trigger: "blur"
        }],
        username: [{
          validator: checkUserName,
          trigger: "blur"
        }],
        checktrue: [{
          validator: checkT,
          trigger: "change"
        }],
      },
      rules2: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
        registerHospitalName: [{
          validator: checkHosp,
          trigger: "blur"
        }],
        organizationName: [{
          validator: checkServeId,
          trigger: "blur"
        }],
        username: [{
          validator: checkUserName,
          trigger: "blur"
        }],
        checktrue: [{
          validator: checkT,
          trigger: "change"
        }],
      },
    };
  },
  created() {
    this.getDoclist();
  },
  mounted() {
    //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    // this.getProductBrand(0, "", 0);
  },
  methods: {
    getDoclist() {
      var _this = this
      let code = GetParam(window.location.href.split("#")[0], "code");
      // 这里则是根据授权登录后回调到当前h5地址，从当前地址导航栏拿取code,根据实际开发的应用场景，比如向我们项目则是调用一个有后台提供的获取微信个人信息的接口，这个接口是后台根据拿取到的code去请求微信官方文档获取个人信息的接口，下面是我根据我实际业务场景编写的，主要是根据code获取个人信息保存。授权登录走到这一步基本就做完了
      axios
        .post(
          `${api.urlHead}/wx/mp/userInfo?gzhCode=${code}`,
          config
        )
        .then((res) => {
          if (res.data.result == "success") {
            sessionStorage.setItem("userinfo", JSON.stringify(res.data));
            this.headImgUrl = res.data.headImgUrl;
            this.nickName = res.data.nickName;
            this.openId = res.data.openId;
            axios
              .get(
                api.urlHead + api.getdoclist, {
                params: {
                  openId: this.openId,
                },
              },
                config
              )
              .then(async (res1) => {
                let redata = res1.data.data.records;
                if (redata.length > 0 && redata[0].phone.length >= 8) {
                  sessionStorage.setItem("phone", redata[0].phone)
                  _this.$message.success({
                    showClose: true,
                    message: "账户已绑定",
                    duration: 1000,
                  });
                  await _this.getuserinfo(redata[0].phone)
                  let sesurl = JSON.parse(sessionStorage.getItem("urlAll"))
                  console.log("bindpage:", sesurl[0].path, this.$router, sesurl[0].query, sesurl[0].path);
                  let queryKeys = Object.keys(sesurl[0].query), queryValues = Object.values(sesurl[0].query)
                  let queryStr=[]
                  if(queryKeys.length>0){
                    queryKeys.map((item,index)=>{
                      queryStr[index] = `${item}=${queryValues[index]}`
                    })
                  }
                  // if (sesurl[0].path === "/recruitList" || sesurl[0].path === "/recruitDetail") {
                  //   sessionStorage.setItem("isBind", 1)
                  //   window.location.href = queryKeys.length>0?"https://s.yi-lian.net/#" + sesurl[0].path+"?"+queryStr.join("&"):"https://s.yi-lian.net/#" + sesurl[0].path
                  //   // _this.$router.push({
                  //   //   path: sesurl[0].path,
                  //   //   query: sesurl[0].query
                  //   // });
                  // } else {
                  //   _this.$router.push("/recruitList")//目前限制为只能访问临床研究
                  // }
                 sessionStorage.setItem("isBind", 1)
                  if (!sesurl || sesurl[0].path === "/bindpage") {
                    window.location.href="https://s.yi-lian.net"
                  } else {
                       window.location.href = queryKeys.length>0?"https://s.yi-lian.net/#" + sesurl[0].path+"?"+queryStr.join("&"):"https://s.yi-lian.net/#" + sesurl[0].path
                  }
                } else {
                  _this.$message({
                    showClose: true,
                    message: "账户未绑定",
                    duration: 1000,
                  });
                  _this.pageshow = true;
                }
              })
              .catch((err) => { });
          } else {
            _this.$message.error({
              message: "code请求失败",
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          _this.$message.error({
            message: err,
            duration: 1000,
          });
        });
    },
    async getuserinfo(ph) {
      let databyph = {
        phone: ph
      }
      const userinfo = await searchDoc(databyph)
      if (userinfo.code === 200) {
        let userdata = {
          name: userinfo.data.records[0].name,
          nickName: userinfo.data.records[0].nickName,
          headImgUrl: userinfo.data.records[0].headImgUrl,
          phone: userinfo.data.records[0].phone,
          isRealName: userinfo.data.records[0].isRealName,
          agoraUid: userinfo.data.records[0].agoraUid,
        }
        sessionStorage.setItem("userinfo", JSON.stringify(userdata))
      }
    },
    //  验证
    async getAuthCode(formName) {
      var _this = this
      _this.$refs[formName].validateField("name", async (nameErro) => {
        if (nameErro) {
          return false;
        } else {
          let sdata = new FormData();
          sdata.append("phone", _this.$refs[formName].model.name);
          sdata.append("yzm", _this.$refs[formName].model.verification);
          sdata.append("countCache", "bind");
          sdata.append("smsCache", "dx");
          axios
            .post(
              api.urlHead + api.sendmsm,
              sdata,
              config
            )
            .then(async (res) => {
              if (res.data.msg === "成功") {
                _this.$message.success({
                  message: "发送成功",
                  duration: 1000,
                });
                _this.sendAuthCode = false;
                //设置倒计时秒
                _this.auth_time = 60;
                var auth_timetimer = setInterval(() => {
                  _this.auth_time--;
                  if (_this.auth_time <= 0) {
                    _this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              } else {
                _this.$message.error({
                  message: res.data.msg,
                  duration: 1000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    submit(formName) {
      var _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.logining = true;
          let bdata = {
            phone: _this.$refs[formName].model.name,
            yzm: _this.$refs[formName].model.verification,
            registerHospitalName: _this.$refs[formName].model.registerHospitalName,
            organizationName: _this.$refs[formName].model.organizationName,
            name: _this.$refs[formName].model.username,
            nickName: _this.nickName,
            openId: _this.openId,
            headImgUrl: _this.headImgUrl,
            isRegister: "1",
          };
          let fdata = new FormData();
          fdata.append("phone", _this.$refs[formName].model.name);
          fdata.append("yzm", _this.$refs[formName].model.verification);
          fdata.append("countCache", "bind");
          fdata.append("smsCache", "dx");
          axios
            .post(
              api.urlHead + api.smscheck,
              fdata,
              config
            )
            .then(async (res) => {
              let result = res.data;
              if (result.msg === "验证码错误") {
                _this.$message.error({
                  message: result.msg,
                  duration: 1000,
                });
              } else {
                const sresult = await bindsubmit(bdata);
                await _this.getuserinfo(bdata.phone)
                if (sresult.msg === "操作成功") {
                  _this.$message.success({
                    message: sresult.msg,
                    duration: 1000,
                  });
                  sessionStorage.setItem("userinfo", JSON.stringify(bdata))
                  let sesurl = JSON.parse(sessionStorage.getItem("urlAll"))
                  if (!sesurl || sesurl[0].path === "/bindpage") {
                    _this.$router.push('/')
                  } else {
                    _this.$router.push({
                      path: sesurl[0].path,
                      query: sesurl[0].query
                    });
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("submit err");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};